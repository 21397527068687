import {
  browserTracingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  thirdPartyErrorFilterIntegration,
} from "@sentry/react";
import type { Integration } from "@sentry/types";
import { useEffect } from "react";

import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "@shared/react-router-dom";
import {
  isSandbox,
  isProductionBundle,
  isProduction,
} from "@shared/utils/fullscriptEnv/fullscriptEnv";

import { beforeSend } from "./beforeSend/beforeSend";

interface Props {
  enableRouterInstrumentation?: boolean;
  tracesSampleRate?: number;
  enableSentryTunnel?: boolean;
}

const getIntegrations = (
  enableRouterInstrumentation: boolean,
  isReplayCompressionEnabled: boolean = true
): Integration[] => {
  // Don't enable transactions and replays in staging, sandbox, development or test
  if (!isProduction()) return [];

  const replays = replayIntegration({ useCompression: isReplayCompressionEnabled });

  // Allows checkout, oauth, unsubscribe etc to work
  // Required to support SPAs that don't use ReactRouter
  if (!enableRouterInstrumentation) {
    return [browserTracingIntegration(), replays];
  }

  return [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      stripBasename: false,
    }),
    replays,
    thirdPartyErrorFilterIntegration({
      filterKeys: ["hw-admin-js"],
      behaviour: "apply-tag-if-contains-third-party-frames",
    }),
  ];
};

const environment = () => {
  if (isProduction()) {
    return "production";
  } else if (isSandbox()) {
    return "sandbox";
  } else if (process.env.FORCE_ENABLE_SENTRY === "true") {
    return "development";
  } else {
    return "staging";
  }
};

const initSentry = ({
  enableRouterInstrumentation,
  tracesSampleRate,
  enableSentryTunnel = false,
}: Props = {}) => {
  init({
    dsn: "https://b1e6050ac9de430b807ad68a57ea5816@o927579.ingest.sentry.io/5878763",
    tunnel: enableSentryTunnel ? "/api/sentry/reports" : undefined,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: getIntegrations(enableRouterInstrumentation, !enableSentryTunnel),
    tracesSampleRate: isProduction() ? tracesSampleRate || 1.0 : 0.0,
    enabled: process.env.FORCE_ENABLE_SENTRY === "true" || isProductionBundle(),
    environment: environment(),
    release: process.env.CI_COMMIT_SHA,
    denyUrls: [
      // browser extensions
      /extension:\/\//gi,
      /^file:\/\//gi,
      /webkit-masked-url:/i,
    ],
    beforeSend,
  });
};

export { initSentry };
