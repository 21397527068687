const isSentryTunnelEnabled = (): boolean => {
  try {
    const div = document.querySelector("div[data-react-props]");
    if (!div) return false;

    const flippers = JSON.parse(div.getAttribute("data-react-props") || "{}").flippers || [];

    return flippers.includes("sentry_tunnel");
  } catch (e) {
    return false;
  }
};

export { isSentryTunnelEnabled };
